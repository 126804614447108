<template>
  <div class="basket">

    <loader v-if="is.initial" variant="primary"/>

    <c-leaf v-else-if="basket.itemCount <= 0" class="basket-emptymessage">
      <b-row align-h="center" align-v="center">
        <b-col cols="auto">
          <div class="emptymessage-icon">
            <b-icon icon="image-placeholder" font-scale="8"/>
          </div>
        </b-col>

        <b-col :cols="!$root.isMobile ? 'auto' : 12">
          <div class="emptymessage-content">
            <slot name="emptymessage"/>
          </div>
        </b-col>
      </b-row>
    </c-leaf>

    <b-row v-else>
      <b-col lg="8" tag="article">
        <h4>{{ $t(`${tPath}.packshot.overview.title`, { count: basket.itemCount }) }}</h4>
        <c-leaf>
          <b-row :cols="contentCols.xs" :cols-sm="contentCols.sm" :cols-md="contentCols.md" :cols-lg="contentCols.lg" :cols-xl="contentCols.xl">
            <b-col v-for="item in basket.items" :key="item.basketItemId">
              <div class="p-1 mb-2 bg-light packshot">
                <img class="packshot-image" :src="item.product.image" />
                <div class="item-actions d-flex justify-content-end">
                  <a href="#" @click.prevent="deleteItem(item)" :title="$t(`${tPath}.packshot.deleteitem`)"><b-icon icon="trash"/></a>
                </div>
              </div>
            </b-col>
          </b-row>
          <div class="items-footer">
              <div class="footer-actions">
                <b-link @click.prevent="deleteBasket"><b-icon icon="trash"/> {{ $t(`${tPath}.packshot.deletebasket`) }}</b-link>
              </div>
            </div>
        </c-leaf>
      </b-col>

      <b-col lg="4" tag="aside" class="mt-3 mt-lg-0">
        <h4>{{ $t(`${tPath}.packshot.download.title`) }}</h4>
        <b-button @click="downloadPackShots" variant="primary"><b-icon icon="b-download"/> {{ $t(`${tPath}.packshot.download.button`) }}</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ASIDE_COLUMN_WIDTHS } from '@/assets/js/config/client'
import Loader from '@/components/Loader'

export default {
  name: 'Basket',
  components: {
    Loader
  },
  computed: {
    is () {
      return this.$store.getters['packshotlistcart/is']
    },
    basket () {
      return this.$store.getters['packshotlistcart/get']
    }
  },
  data () {
    return {
      contentCols: ASIDE_COLUMN_WIDTHS
    }
  },
  methods: {
    downloadPackShots () {
      this.$store.dispatch('packshotlistcart/zipFile', { fileName: this.$t(`${this.tPath}.packshot.download.filename`) }).then(response => {
        var filename = ''
        var disposition = response.headers['content-disposition']
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          var matches = filenameRegex.exec(disposition)
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }
        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(response.data)
        a.download = filename
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        a.remove()
        this.$store.dispatch('packshotlistcart/close')
      })
    },
    deleteBasket () {
      this.$store.dispatch('packshotlistcart/delete')
    },
    deleteItem (item) {
      this.$store.dispatch('packshotlistcart/deleteItem', { itemId: item.basketItemId, omitLastAction: true })
    }
  },
  created () {
    this.$store.dispatch('packshotlistcart/get')

    window.addEventListener('beforeprint', this.beforePrint)
    window.addEventListener('afterprint', this.afterPrint)
  },
  beforeDestroy () {
    window.removeEventListener('beforeprint', this.beforePrint)
    window.removeEventListener('afterprint', this.afterPrint)
  }
}
</script>

<style lang="scss">
// mobile

$basket-emptymessage-icon-gap: $spacer !default;
$basket-emptymessage-content-text-align: center !default;

$basket-items-footer-actions-gap-y: $spacer * 0.5 !default;
$basket-items-footer-actions-gap-x: $spacer * 2 !default;

// tablet

$basket-tablet: $mobile-breakpoint !default;

$basket-tablet-emptymessage-icon-gap: 0 !default;
$basket-tablet-emptymessage-content-text-align: inherit !default;

$basket-tablet-items-grid-columns: [start] auto 4fr 1fr 1fr 1fr [end] !default;

$basket-tablet-items-header-font-size: $font-size-sm !default;
$basket-tablet-items-header-color: $dark !default;
$basket-tablet-items-header-line-height: 1.1 !default;

// desktop

$basket-desktop: $desktop-breakpoint !default;

.basket {
  @include component('y');

  .basket-emptymessage {
    @include component('y');

    .emptymessage-icon {
      margin-bottom: $basket-emptymessage-icon-gap;
    }

    .emptymessage-content {
      text-align: $basket-emptymessage-content-text-align;
    }
  }

  @include media-breakpoint-up($basket-tablet) {
    .basket-emptymessage {
      .emptymessage-icon {
        margin-bottom: $basket-tablet-emptymessage-icon-gap;
      }

      .emptymessage-content {
        text-align: $basket-tablet-emptymessage-content-text-align;
      }
    }
  }

  .packshot{
    position:relative;

    &-image{
      width:100%;
      margin-bottom:0;
    }

    .item-actions{
      position:absolute;
      bottom:0;
      left: 0;
      right: 0;
      padding: 0.5rem;
      background-color: rgba($light, 0.50)
    }
  }
}

@media print {
  .basket-print-mode {
    #app {
      > header,
      > footer {
        display: none!important;
      }

      > main {
        .basket ~ * {
          display: none!important;
        }
      }
    }
  }
}
</style>
